.costsDashboardContainer {
  padding: 20px;
  background-color: #2E3B4E;
  border-radius: 8px;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full height of viewport */
}

.dashboardTitle {
  font-size: 24px;
  margin-bottom: 20px;
}

.inputsContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Ensure responsiveness */
}

.selectContainer {
  flex: 1;
  margin-right: 10px;
}

.selectContainer label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.chartContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: calc(100vh - 160px); /* Adjust max-height to fit within the viewport */
}
