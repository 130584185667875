.navFilterContent {
  position: relative;
}

.navFilterSelected {
  width: 100%;
  background-color: var(--dobcon-darkest-gray);
  border: 1.5px solid var(--dobcon-gray);
  padding: .35rem;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navFilterSelected .fa-filter {
  margin-right: .5rem;
  font-size: 15px;
}

.navFilterOptions {
  display: none;
  position: absolute;
  background-color: var(--dobcon-darkest-gray);
  border: 1px solid var(--dobcon-gray);
  z-index: 1;
  width: 100%;
  /* Asegura que el menú desplegable ocupe todo el ancho del contenedor */
}

.navFilterOptions:last-child {
  border-bottom: none;
}

.navFilterOption {
  width: 100%;
  display: inline-block;
  padding: .3rem .5rem;
  border-bottom: 1.5px solid var(--dobcon-gray);
}

.navFilterOption:hover {
  background-color: #00000049;
}

/* Mostrar las opciones cuando el estado isOpen es verdadero */
.navFilterContent .navFilterOptions {
  display: block;
}

/* Opcional: Animación para el ícono de ángulo hacia abajo */
.fa-angle-down {
  transition: transform 0.3s;
}

.fa-angle-down.open {
  transform: rotate(-180deg);
}