.dragAndDropBox {
  width: 100%;
  text-align: center;
  padding: 1.2rem 2rem 2rem;
  border: 1.5px solid var(--dobcon-medium-gray);
  background-color: var(--dobcon-dark-gray);
  transition: .5s;
}

.dragging {
  background-color: var(--dobcon-darkest-gray);
}