.iconFilterContainer {
  position: relative;
}

.iconFilterSelected {
  width: 100%;
  background-color: var(--dobcon-gray);
  border: 1.5px solid var(--dobcon-medium-gray);
  padding: .3rem;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.iconFilterIcon {
  margin: 0 .5rem -1.5px 0;
  height: 16px;
}

.iconFilterOptions {
  width: 100%;
  border: 1px solid var(--dobcon-medium-gray);
  background-color: var(--dobcon-gray);
  position: absolute;
  max-height: 185px;
  overflow: auto;
  z-index: 1;
}

.iconFilterOptions:last-child {
  border-bottom: none;
}

.iconFilterOption {
  padding: .35rem .5rem;
  cursor: pointer;
  border-bottom: 1px solid var(--dobcon-medium-gray);
  transition: .5s;
}

.iconFilterOption:hover {
  background-color: var(--dobcon-dark-gray);
}