.companyResourcesTable {
  width: 100%;
  border: 1px solid var(--dobcon-medium-gray);
  border-radius: 5px;
  background-color: var(--dobcon-gray);
}

.companyResourcesTable table {
  width: 100%;
  table-layout: auto;
}

.companyResourcesTableBody {
  width: 100%;
  max-height: 285px;
  overflow-y: auto;
}

.companyResourcesTableHead {
  position: sticky;
  top: 0;
}

.companyResourcesTable table tr th {
  background-color: var(--dobcon-dark-gray);
  border-bottom: 3px solid var(--dobcon-red);
  padding: 1.5rem .5rem;
  text-align: left;
  font-size: 18.5px;
}

.companyResourcesTable table tr th:first-child {
  border-radius: 5px 0 0 0;
}

.companyResourcesTable table tr th:last-child {
  border-radius: 0 5px 0 0;
}

.companyResourcesTable table tr th:first-child,
.companyResourcesTable table tr td:first-child {
  padding-left: 1rem;
}

.companyResourcesTable table tr th:last-child,
.companyResourcesTable table tr td:last-child {
  padding-right: 1rem;
}

.companyResourcesTable table tr td {
  font-weight: 500;
  padding: .75rem .5rem;
  border-bottom: 1px solid var(--dobcon-medium-gray);
}

.companyResourcesTable table tr:hover {
  background-color: var(--dobcon-dark-gray);
}

.companyResourcesTable table tr td button {
  color: var(--dobcon-light-gray);
}

.companyResourcesTable table tr td button:hover {
  color: var(--dobcon-medium-gray);
}

.companyResourcesTableFooter {
  padding: 1rem;
  position: relative;
  z-index: 1;
}
