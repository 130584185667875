.progressSlaCard {
  width: 13.7%;
  border: 1.5px solid var(--dobcon-medium-gray);
  border-radius: 5px;
  margin-right: 8px;
}

.progressSlaCard:last-child {
  margin-right: 0;
}

.progressSlaCardHead {
  background-color: var(--dobcon-dark-gray);
  border-radius: 5px 5px 0 0;
  padding: .5rem 0;
  text-align: center;
}

.progressSlaCardBody {
  background-color: var(--dobcon-gray);
  border-radius: 0 0 5px 5px;
  padding: .75rem;
}

.progressSlaCardDivision {
  border-top: 1.5px solid var(--dobcon-medium-gray);
  width: 30%;
}

@media only screen and (max-width: 575px) {

  .progressSlaCard {
    width: 60%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {

  .progressSlaCard {
    width: 27.5%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .progressSlaCard {
    width: 22%;
  }
}