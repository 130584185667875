.footerCopy {
  width: 100%;
  text-align: center;
  padding: 1rem;
  background-color: var(--dobcon-darkest-gray);
}

.footerCopy p {
  font-size: 14px;
  font-weight: 100;
}