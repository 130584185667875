.graphModalBack {
  background-color: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: .5s;
  opacity: 0;
}

.graphModalContent {
  background-color: var(--dobcon-gray);
  padding: 2.5rem;
  width: 75%;
  max-height: 90%;
  overflow-y: auto;
  border-radius: 10px;
  position: relative;
}

.graphModalContent .fa-circle-xmark {
  position: absolute;
  top: -2rem;
  right: -2rem;
  cursor: pointer;
}

.graphModalCloseButton {
  position: sticky;
  top: 0;
  right: 0;
  cursor: pointer;
}

.graphModalTitle {
  display: flex;
  justify-content: center;
}

.graphModalBack.modalActive {
  animation: modalAppears .5s forwards;
}

@keyframes modalAppears {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}