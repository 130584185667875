.initiativesTable {
  width: 100%;
  border: 1px solid var(--dobcon-medium-gray);
  border-radius: 5px;
  background-color: var(--dobcon-gray);
}

.initiativesTable table {
  width: 100%;
  table-layout: auto;
}

.initiativesTableBody {
  width: 100%;
  max-height: 385px;
  overflow-y: auto;
}

.initiativesTableHead {
  position: sticky;
  top: 0;
  z-index: 6;
}

.initiativesTableFoot {
  position: sticky;
  bottom: 0;
  z-index: 1;
}

.initiativesTableFoot tr td {
  background-color: var(--dobcon-gray);
}

.initiativesTable table tr th {
  background-color: var(--dobcon-dark-gray);
  border-bottom: 3px solid var(--dobcon-red);
  padding: 1.5rem .5rem;
  text-align: left;
  font-size: 18.5px;
}

.initiativesTable table tr th:first-child {
  border-radius: 5px 0 0 0;
}

.initiativesTable table tr th:last-child {
  border-radius: 0 5px 0 0;
}

.initiativesTable table tr th:first-child,
.initiativesTable table tr td:first-child {
  padding-left: 1rem;
}

.initiativesTable table tr th:last-child,
.initiativesTable table tr td:last-child {
  padding-right: 1rem;
}

.initiativesTable table tr td {
  font-weight: 500;
  padding: .75rem .5rem;
  border-bottom: 1px solid var(--dobcon-medium-gray);
}

.initiativesTable table tr:hover {
  background-color: var(--dobcon-dark-gray);
}

.initiativesTable table tr td button {
  color: var(--dobcon-light-gray);
}

.initiativesTable table tr td button:hover {
  color: var(--dobcon-medium-gray);
}