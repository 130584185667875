.dateInput {
  position: relative;
  width: 100%;
}

.dateInput input {
  width: 100%;
  background-color: var(--dobcon-darkest-gray);
  border: 1.5px solid var(--dobcon-medium-gray);
  padding: .35rem;
  border-radius: 5px;
  color: #FFF;
}

.hasLabel {
  margin-top: .5rem;
}

.dateInput label {
  margin: 0rem .5rem;
  color: var(--dobcon-lighter-gray);
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
  cursor: pointer;
}