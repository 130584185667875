.intelligent-report-widget {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9999;
  background-color: var(--dobcon-dark-red);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.intelligent-report-widget:hover {
  background-color: var(--dobcon-medium-red);
}

.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}

.gif-loader {
  width: 300px;
  filter: hue-rotate(-200deg) saturate(4000%) brightness(0.7);
}

.spinner {
  border: 8px solid var(--dobcon-dark-gray);
  border-top: 8px solid var(--dobcon-light-red);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite; 
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.modal {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  border-radius: 10px;
  background-color: var(--dobcon-dark-gray);
  margin: 2% auto;
  width: 60%; /* Adjusted width for larger modal */
  height: 100%; /* Adjusted height */
  color: white;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.modal h1 {
  text-align: center;
  padding-top: 30px; /* Increased padding for better spacing */
  padding-bottom: 15px;
  font-weight: bold;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.6);
  font-size: 32px; /* Increased font size */
}

.modal h2 {
  margin-top: 15px; /* Adjusted margin */
  margin-bottom: 15px;
  font-weight: bold;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.6);
  font-size: 26px; /* Increased font size */
}

.modal p {
  text-align: justify;
  margin-bottom: 10px; /* Increased margin for better spacing */
  font-size: 16px; /* Adjusted font size */
}

.report-header {
  background-color: var(--dobcon-gray);
  position: sticky;
  top: 0;
  z-index: 10;
}

.health-icon,
.prediction-icon {
  width: 120px; /* Increased icon size */
  padding: 20px;
}

.widget-icon {
  width: 150px;
  color: var(--dobcon-light-red);
}

.vertical-line {
  width: 3px; /* Adjusted the thickness of the line */
  height: 130px; /* Adjusted height to match the icon */
  background-color: #ccc; /* Line color */
  margin: 0 20px; /* Spacing around the line */
  margin-bottom: 10px;
}

.report-summary {
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center; /* Centers the content horizontally */
}

.report-info {
  text-align: left;
  max-width: 70%; /* Adjusted width to fit larger modal */
  padding-bottom: 15px;
  font-size: 16px; /* Adjusted font size */
}

.report-info .top p {
  font-weight: normal;
  font-size: 18px;
  margin: 1.5px;
}

.report-info .top strong {
  font-weight: bolder;
}

.report-info .bottom p {
  width: 250px;
  font-size: 14px;
  font-weight: normal;
  margin-left: 2px;
}

.report-content {
  max-height: calc(100% - 300px); /* Adjusted height based on new modal size */
  overflow-y: auto;
  background-color: var(--dobcon-dark-gray);
  padding: 0px 50px; /* Adjusted padding for better content alignment */
}

.section-content p {
  font-size: 16px;
  padding-left: 50px;
  font-weight: normal;
}

.reportStepTag {
  padding: .35rem 2rem;
  background-color: var(--dobcon-medium-red);
  margin-top: -1px;
  display: inline-block;
  font-size: 14px;
}

.report-footer {
  position: sticky;
  bottom: 0;
  z-index: 10;
}

.report-prediction {
  display: flex;
  background-color: var(--dobcon-gray);
  padding: 10px;
  border-radius: 5px;
  align-items: center;
}

.report-prediction p {
  font-size: 16px; /* Adjusted font size */
  padding-right: 20px;
}

.report-disclaimer {
  border-top: 3px solid var(--dobcon-red);
  padding: 25px; /* Increased padding */
  background-color: var(--dobcon-dark-gray);
}

.report-disclaimer-content {
  display: flex;
  justify-content: space-between; /* Distribute space evenly */
  align-items: center;
}

.report-disclaimer p {
  font-size: 16px; /* Adjusted font size */
  padding-left: 30px; /* Adjusted padding */
}

.report-disclaimer button {
  width: 150px;
}

.pdf-button {
  height: 10px;
  display: inline-flex;
  align-items: center;
  padding: 15px 30px; /* Increased padding */
  background-color: gray;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 14px; /* Adjusted font size */
  font-weight: bold;
}

.pdf-button i {
  margin-right: 8px; /* Spacing between icon and text */
}

.feedback-modal-content {
  padding: 30px;
  border-radius: 10px;
  background-color: var(--dobcon-dark-gray);
  margin: 3% auto;
  width: 20%;
  color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.modal .feedback-modal-content h1 {
  text-align: left;
  padding-top: 0;
  padding-bottom: 10px;
  font-weight: bold;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.6);
  font-size: 28px;
}

.feedback-comment {
  height: 30%;
}

.modal .feedback-modal-content p {
  margin: 0;
  font-size: 18px;
}

.feedback-button-container {
  display: flex;
  justify-content: flex-end;
}

.close-btn {
  color: var(--dobcon-lighter-gray);
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close-btn:hover,
.close-btn:focus {
  color: var(--dobcon-light-red);
}

.star-rating {
  display: flex;
  margin: 0px 20px;
  justify-content: space-between;
  text-align: center;
  font-size: 40px;
}

.star-rating span {
  cursor: pointer;
  color: gray; /* Default color when not selected */
  transition: color 0.2s;
}

.star-rating .selected {
  color: white; /* Color when the star is selected */
}

.star-rating span:hover {
  color: white; /* Optional hover color */
}
