/*tooltip table*/

.data-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
}

.data-table th,
.data-table td {
    border: none;
    text-align: left;
    padding: 8px;
}

.data-table th {
    background-color: var(--dobcon-light-gray);
    color: white;
}


.data-table tr:not(:last-child) td {
    border-bottom: 1px solid #ddd;
}

.data-table td:not(:last-child), .data-table th:not(:last-child) {
    border-right: 1px solid #ddd;
}

/*timeframe filter*/
.graphModalFooter {
    display: flex; 
    align-items: center;
    justify-content: flex-end; 
    padding: 5px 0; 
}

.graphFilterLabel {
    margin-right: 10px; /* Adjust this value as needed for spacing */
    white-space: nowrap; /* Prevents text from wrapping to a new line */
  }

.chart-container {
    height: auto;
    position: 'relative';
}
