.basicTabHead {
  display: flex;
}

.basicTabHeadTabs {
  padding: 1rem;
  cursor: pointer;
  border: 1px solid var(--dobcon-medium-gray);
  border-bottom: 3px solid var(--dobcon-red);
  background-color: var(--dobcon-gray);
  transition: .5s;
  color: var(--dobcon-light-gray);
}

.basicTabHeadTabs:hover {
  background-color: var(--dobcon-dark-gray);
}

.basicTabHeadTabs:first-child {
  border-radius: 10px 0 0 0;
}

.basicTabHeadTabs:last-child {
  border-radius: 0 10px 0 0;
}

.activeTab {
  background-color: var(--dobcon-dark-gray);
  color: #FFF;
}

.basicTabBody {
  margin-top: -1px;
  padding: 1.5rem;
  background-color: var(--dobcon-gray);
  border: 1px solid var(--dobcon-medium-gray);
  border-radius: 0 10px 10px 10px;
}