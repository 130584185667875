.resourcesFileModalBack {
  background-color: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: .5s;
  opacity: 0;
}

.resourcesFileModalContent {
  background-color: var(--dobcon-gray);
  padding: 2rem;
  width: 900px;
  border-radius: 10px;
  max-height: 530px;
  overflow: auto;
}

.resourcesFileModalBack.modalActive {
  animation: modalAppears .5s forwards;
}

@keyframes modalAppears {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Steps */
.resourcesFileStep {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.resourcesFileStep span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px!important;
  height: 50px!important;
  border-radius: 50px;
  background-color: var(--dobcon-dark-gray);
  color: var(--dobcon-medium-gray);
  font-size: 20px;
  margin-right: .5rem;
}

.resourcesFileStep p {
  font-weight: 500;
  color: var(--dobcon-light-gray);
}

.resourcesFileStepActive span {
  color: var(--dobcon-light-gray);
}
.resourcesFileStepActive p {
  color: #FFF;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {

  .resourcesFileModalContent {
    max-height: 300px;
  }
}

@media only screen and (max-width: 767px) {

  .resourcesFileModalContent {
    width: 95%;
    padding: 1.75rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .resourcesFileModalContent {
    width: 92.5%;
  }

  .resourcesFileStep {
    width: 33%;
  }
}