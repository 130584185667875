.adoptionBanner {
  background-image: url("../../../public/img/others/dobcon-adoption-background.png");
  background-size: cover;
  height: 400px;
}

.adoptionContainer {
  height: 400px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.adoptionImg {
  height: 185px;
  width: auto;
}

.adoptionLogo {
  height: 55px;
}

/* Cards */
.adoptionCardsContainer {
  display: -webkit-box;
  overflow-x: auto;
  padding-bottom: 1.5rem;
}

@media only screen and (max-width: 575px) {

  .adoptionBanner,
  .adoptionContainer {
    height: 325px;
  }

  .adoptionImg {
    height: 100px;
    width: auto;
    margin-bottom: 1rem;
  }

  .adoptionLogo {
    height: 45px;
  }
}