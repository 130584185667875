.darkSelectInput {
  position: relative;
  margin: .85rem 0;
  cursor: pointer;
}

.darkSelectInputContainer {
  text-align: left;
  border: 1.5px solid var(--dobcon-medium-gray);
  background-color: var(--dobcon-darkest-gray);
  border-radius: 5px;
  padding: .5rem;
  width: 100%;
  position: relative;
  margin-top: .5rem;
}

.darkSelectInput label {
  margin: 0rem .5rem;
  color: var(--dobcon-lighter-gray);
  font-size: 15px;
}

.darkSelectInputSelected {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.darkSelectInputOptions {
  width: 100%;
  max-height: 150px;
  border: 1px solid var(--dobcon-medium-gray);
  background-color: var(--dobcon-darkest-gray);
  position: absolute;
  z-index: 100;
  left: 0;
  top: 38px;
  box-shadow: 0px 6px 41px -15px rgba(0,0,0,0.75);
  overflow-y: auto;
}

/* New class for opening upwards */
.darkSelectInputOptions.open-upwards {
  bottom: 38px; /* Adjust based on your design */
  top: auto; /* Override the default top value */
}

.darkSelectInputOptions:last-child {
  border-bottom: none;
}

.darkSelectInputOption {
  padding: .35rem .5rem;
  cursor: pointer;
  border-bottom: 1px solid var(--dobcon-medium-gray);
  transition: .5s;
}

.darkSelectInputOption .darkSelectInputLink  {
  display: inline-block;
  width: 100%;
}

.darkSelectInputOption:hover {
  background-color: #00000049;
}

/* Sizes */
.darkSelectInputMini {
  margin-top: 0;
}

.darkSelectInputMini .darkSelectInputContainer {
  margin-top: 0;
}

.darkSelectInputMini .darkSelectInputContainer .darkSelectInputSelected {
  font-size: 15px;
}

.darkSelectInputMini .darkSelectInputContainer .darkSelectInputOptions {
  font-size: 15px;
}

.darkSelectInputSmall {
  margin-top: -.5rem;
}

.darkSelectInputSmall .darkSelectInputContainer {
  width: 185px;
  margin-top: 0;
}

.darkSelectInputMedium {
  margin-top: -.5rem;
}

.darkSelectInputMedium .darkSelectInputContainer {
  width: 275px;
  margin-top: 0;
}

@media only screen and (max-width: 575px) {

  .darkSelectInputMedium .darkSelectInputContainer {
    width: 200px;
  }  
}