.detailsModalBack {
  background-color: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: .5s;
  opacity: 0;
}

.detailsModalContent {
  background-color: var(--dobcon-gray);
  padding: 2.5rem;
  max-height: 90%;
  width: 75%;
  border-radius: 10px;
  position: relative;
  overflow-y: auto;
}

.detailsModalContent .fa-circle-xmark {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.detailsModalBack.modalActive {
  animation: modalAppears .5s forwards;
}

@keyframes modalAppears {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}