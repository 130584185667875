.settingsCard {
  width: 100%;
  min-height: 120px;
  background-color: var(--dobcon-gray);
  padding: 1.75rem 1.75rem 1.75rem 1.5rem;
  display: flex;
  align-items: center;
  border-left: 5px solid var(--dobcon-red);
  border-radius: 5px;
  position: relative;
}

.settingsCardIcon {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  font-size: 25px;
  color: var(--dobcon-medium-gray);
  transition: .5s;
}

.settingsCard:hover .settingsCardIcon {
  right: .8rem;
}