.userInfoCard {
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--dobcon-medium-gray);
  text-align: center;
}

.userInfoCardHead {
  padding: 1.5rem;
  background-color: var(--dobcon-dark-gray);
  border-radius: 5px 5px 0 0;
  border-bottom: 3px solid var(--dobcon-red);
}

.userInfoCardBody {
  border-radius: 0 0 5px 5px;
  padding: 1.5rem 1.5rem 1.75rem;
  background-color: var(--dobcon-gray);
}