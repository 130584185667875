.homeCard {
  width: 100%;
  border: 1px solid var(--dobcon-medium-gray);
}

.homeCardHead {
  height: 75px;
  background-color: var(--dobcon-darkest-gray);
  padding: 1.1rem;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homeCardBody {
  padding: 1.1rem;
}

.homeCardIcon {
  width: 30px;
  opacity: .5;
}