.privilegesTable {
  background-color: var(--dobcon-gray);
  border-radius: 5px;
  border: 1px solid var(--dobcon-medium-gray);
  position: relative;
}

.privilegesTableHead {
  background-color: var(--dobcon-dark-gray);
  border-bottom: 3px solid var(--dobcon-red);
  padding: 1.5rem 2rem;
  text-align: left;
  font-size: 18.5px;
  position: sticky;
  top: 0;
  border-radius: 5px 5px 0 0;
}

.privilegesTableBody {
  padding: 1.5rem 1rem;
  max-height: 285px;
  overflow-y: auto;
}

.privilegesTableSection {
  border: 1px solid var(--dobcon-medium-gray);
  border-radius: 5px;
  padding: 0 1rem 1rem;
}

.privilegesTableList {
  margin-top: 1rem;
}

.privilegesTableList li {
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-top: .5rem;
}