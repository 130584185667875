.basicOptions {
  position: relative;
}

.basicOptionsContainer {
  position: relative;
  z-index: 0;
}

.basicOptionsSelected {
  width: 100%;
  padding: .3rem;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.basicOptionsIcon {
  margin: 0 .5rem -1.5px 0;
  height: 16px;
}

.basicOptionsOptions {
  width: 200px;
  border: 1px solid var(--dobcon-medium-gray);
  background-color: var(--dobcon-gray);
  position: absolute;
  max-height: 185px;
  overflow: auto;
  z-index: 5;
  right: 100%;
  top: 0;
}

.basicOptionsOptions:last-child {
  border-bottom: none;
}

.basicOptionsOption {
  padding: .35rem .5rem;
  cursor: pointer;
  border-bottom: 1px solid var(--dobcon-medium-gray);
  transition: .5s;
}

.basicOptionsOption:hover {
  background-color: var(--dobcon-dark-gray);
}