.darkInput {
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
}

.darkInput input, textarea {
  background-color: var(--dobcon-dark-gray);
  border: 1.5px solid var(--dobcon-medium-gray);
  border-radius: 5px;
  padding: .5rem;
  width: 100%;
  margin-top: .5rem;
}

.darkInput textarea {
  height: 190px;
}

.darkInput label {
  margin: 0rem .5rem;
  color: var(--dobcon-lighter-gray);
}

.darkestInput input, textarea {
  background-color: var(--dobcon-darkest-gray);
  border: 1.5px solid var(--dobcon-medium-gray);
}