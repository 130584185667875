.detailsTable {
  width: 100%;
  max-height: 400px;
  border: 1px solid var(--dobcon-medium-gray);
  border-radius: 5px;
  background-color: var(--dobcon-gray);
  overflow-y: auto;
}

.detailsTable table {
  width: 100%;
  table-layout: auto;
}

.detailsTableHead {
  position: sticky;
  top: 0;
  z-index: 6;
}

.detailsTableFoot {
  position: sticky;
  bottom: 0;
  z-index: 1;
}

.detailsTableFoot tr td {
  background-color: var(--dobcon-gray);
}

.detailsTable table tr th {
  background-color: var(--dobcon-dark-gray);
  border-bottom: 3px solid var(--dobcon-red);
  padding: 1.5rem .5rem;
  text-align: left;
  font-size: 18.5px;
}

.detailsTable table tr th:first-child {
  border-radius: 5px 0 0 0;
}

.detailsTable table tr th:last-child {
  border-radius: 0 5px 0 0;
}

.detailsTable table tr th:first-child,
.detailsTable table tr td:first-child {
  padding-left: 1rem;
}

.detailsTable table tr th:last-child,
.detailsTable table tr td:last-child {
  padding-right: 1rem;
}

.detailsTable table tr td {
  font-weight: 500;
  padding: .75rem .5rem;
  border-bottom: 1px solid var(--dobcon-medium-gray);
}

.detailsTable table tr:hover {
  background-color: var(--dobcon-dark-gray);
}

.detailsTable table tr td button {
  color: var(--dobcon-light-gray);
}

.detailsTable table tr td button:hover {
  color: var(--dobcon-medium-gray);
}

.detailsFilters {
  display: flex;
  padding: 10px 0px 10px 0px;
  justify-content: right;
}

.detailsFilters .col3 {
  width: 300px;
  padding-left: 30px;
}
