.basicInput {
  position: relative;
  margin-bottom: 1.65rem;
}

.basicInput input {
  background-color: transparent;
  border: 1.5px solid var(--dobcon-medium-gray);
  border-radius: 5px;
  padding: .85rem .75rem;
  width: 100%;
}

.basicInput label {
  position: absolute;
  left: 12px;
  top: -10px;
  background-color: var(--dobcon-gray);
  padding: 0 5px;
  color: var(--dobcon-lighter-gray);
  z-index: 1;
}

.passInput {
  position: relative;
}

.passInput i {
  position: absolute;
  top: 15px;
  right: 10px;
  color: var(--dobcon-light-gray);
  transition: .5s;
  cursor: pointer;
}

.passInput i:hover {
  color: var(--dobcon-medium-gray);
}