/* Btn Styles */
.btn {
  border-radius: 5px;
  padding: .75rem;
  text-align: center;
  display: inline-block;
  transition: .5s;
  font-weight: 500;
}

/* Btn Sizes */
.btnMini {
  padding: .5rem;
}

.btnSmall {
  padding: .75rem 1.25rem;
}

.btnMedium {
  width: 48.9%;
}

.btnFull {
  width: 100%;
}

.showGraph {
  width: 100%;
  padding: .55rem;
  margin-top: -9px;
  position: relative;
  top: -3px;
  font-size: 15px;
}

.btnOnGraph {
  width: 100%;
  padding: 0.55rem;
  margin-top: .5rem;
}

/* Btn Colors */
.btnRed {
  background-color: var(--dobcon-red);
}

.btnRed:hover {
  background-color: var(--dobcon-dark-red);
}

.btnBlack {
  background-color: var(--dobcon-dark-gray);
  border: 1px solid var(--dobcon-medium-gray);
}

.btnBlack:hover {
  background-color: var(--dobcon-darkest-gray);
}

.btnGhost {
  border: 2px solid var(--dobcon-light-gray);
}

.btnGhost:hover {
  background-color: #16171e79;
}

/* Types */
.btnUnderline {
  transition: .3s;
  border-bottom: 0px solid transparent;
}

.btnUnderline:hover {
  border-bottom: 1.5px solid #FFF;
}