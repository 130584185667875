/* Font Family */
@font-face {
  font-family: FuturaStd-Light;
  src: url('font/FuturaStd-Light.otf');
}

/* Color guide */
:root {
  --dobcon-darkest-gray: #16171E;
  --dobcon-dark-gray: #1D1D2B;
  --dobcon-gray: #28283C;
  --dobcon-medium-gray: #444463;
  --dobcon-light-gray: #8D8D98;
  --dobcon-lighter-gray: #B4B4B4;
  --dobcon-dark-red: #550513;
  --dobcon-red: #770513;
  --dobcon-medium-red: #A50003;
  --dobcon-light-red: #CF1317;
  /* --dobcon-green: #38AA0A; */
  --dobcon-green: #298a03;
  /* --dobcon-green: #257805; */
}

/* Reseting Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: FuturaStd-Light;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth !important;
}

body {
  background-color: var(--dobcon-dark-gray);
}

a,
ul,
ol,
p,
button,
input,
textarea,
select,
div {
  font-size: 17px;
  font-weight: 600;
  color: #FFF;
}

a {
  text-decoration: none;
  transition: 0.5s;
}

ul {
  list-style: none;
  line-height: 1.4;
}

ol {
  list-style-position: inside;
  line-height: 1.4;
}

hr {
  border: none;
}

p {
  margin: 0;
  line-height: 1.35;
}

strong {
  font-weight: 600;
}

button {
  border: none;
  transition: 0.5s;
  cursor: pointer;
  background: none;
  border-radius: 5px;
  font-weight: 500;
}

table {
  border-collapse: unset;
  border-spacing: unset;
}

tr {
  transition: 0.5s;
}

input,
textarea,
select {
  outline: none;
  border: none;
}

textarea {
  resize: none;
}

::placeholder {
  color: var(--dobcon-light-gray);
  font-weight: 500;
}

/* Scrollbar Syles */

ul::-webkit-scrollbar,
li::-webkit-scrollbar,
div::-webkit-scrollbar {
  width: 7.5px;
  height: 7.5px;
  background-color: var(--dobcon-dark-gray);
}

ul::-webkit-scrollbar-track,
li::-webkit-scrollbar-track,
div::-webkit-scrollbar-track {
  background-color: var(--dobcon-gray);
}

ul::-webkit-scrollbar-thumb,
li::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
  background-color: var(--dobcon-medium-gray);
  border-radius: 5px;
}

/* Containers */
.container {
  max-width: 91%;
  margin: 0 auto;
  padding: 3rem 0;
}

.containerToRight {
  max-width: 91%;
  padding: 4rem 0 2rem;
  margin: 0 auto;
  margin-left: 6.5%;
}

.fullContainer {
  width: 100%;
}

.flexContainer {
  display: flex;
  flex-wrap: wrap;
}

.flex {
  display: flex;
}

.flexWrap {
  flex-wrap: wrap;
}

.sideContainer {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2.5rem;
  height: 100vh;
  overflow-y: auto;
}

/* The "colN" containers are based in a twelve column system, each column represents 8.15 percent */
.col2 {
  width: 16.3%;
}

.col2nHalf {
  width: 20.375%;
}

.col3 {
  width: 23.5%;
}

.col4 {
  width: 32%;
}

.col5 {
  width: 40.75%;
}

.col6 {
  width: 48.9%;
}

.col7 {
  width: 57.05%;
}

.col8 {
  width: 65.2%;
}

.col9 {
  width: 74.2%;
}

.col9nHalf {
  width: 77.425%;
}

.col10 {
  width: 81.5%;
}

.col11 {
  width: 89.65%;
}

.col12 {
  width: 100%;
}

.h100 {
  height: 100vh;
}

/* The wN containers represents literarly the width of the container */

.w15 {
  width: 15%;
}

.w20 {
  width: 20%;
}

.w30 {
  width: 30%;
}

.w35 {
  width: 35%;
}

.w40 {
  width: 40%;
}

.w50 {
  width: 50%;
}

.w60 {
  width: 60%;
}

.w65 {
  width: 65%;
}

.w70 {
  width: 70%;
}

.w80 {
  width: 80%;
}

.w100 {
  width: 100%;
}

@media only screen and (max-width: 575px) {

  .col2,
  .col2nHalf,
  .col3,
  .col4,
  .col5,
  .col6,
  .col7,
  .col8,
  .col9,
  .col9nHalf,
  .col10,
  .col11 {
    width: 100%;
  }

  .col6Mobile {
    width: 48.9%;
  }

  .containerToRight {
    margin-left: auto;
    margin-bottom: 3rem;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {

  .col2,
  .col2nHalf,
  .col3,
  .col4,
  .col5 {
    width: 48.9%;
  }

  .col6,
  .col7,
  .col9,
  .col9nHalf,
  .col8,
  .col10,
  .col11 {
    width: 100%;
  }

  .col3Landscape {
    width: 23.5%;
  }

  .col6Landscape {
    width: 48.9%;
  }

  .col9Landscape {
    width: 74.2%;
  }

  .fullMobile {
    width: 100%;
  }

  .fullLandscape {
    width: 100%;
  }

  .flexLandscape {
    display: flex;
  }

  .containerToRight {
    max-width: 86%;
    margin-left: 11%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .col2,
  .col2nHalf,
  .col3 {
    width: 32%;
  }

  .col4,
  .col5 {
    width: 48.9%;
  }

  .col7,
  .col8,
  .col9,
  .col9nHalf,
  .col10,
  .col11 {
    width: 100%;
  }

  .col4Tablet {
    width: 32%;
  }

  .col6Tablet {
    width: 48.9%;
  }

  .col8Tablet {
    width: 65.2%;
  }

  .fullTablet {
    width: 100%;
  }

  .flexTablet {
    display: flex;
  }

  .containerToRight {
    max-width: 88.5%;
    margin-left: 9.2%;
  }
}

/* Backgrounds Colors*/
.darkestGrayBg {
  background-color: var(--dobcon-darkest-gray);
}

.darkGrayBg {
  background-color: var(--dobcon-dark-gray);
}

.grayBg {
  background-color: var(--dobcon-gray);
}

/* Distribution */
.spaceAround {
  justify-content: space-around;
}

.spaceBetween {
  justify-content: space-between;
}

.spaceEvenly {
  justify-content: space-evenly;
}

.justifyCenter {
  justify-content: center;
}

.flexEnd {
  justify-content: flex-end;
}

.alignCenter {
  align-items: center;
}

.stetchItems {
  align-items: stretch;
}

.wrapReverse {
  flex-wrap: wrap-reverse;
}

.relative {
  position: relative;
}

/* Spacing */
.mAuto {
  margin: 0 auto !important;
}

.m0 {
  margin: 0 !important;
}

.m1 {
  margin: 1rem;
}

.mtHalf {
  margin-top: .5rem;
}

.mt1 {
  margin-top: 1rem;
}

.mt2 {
  margin-top: 2rem;
}

.mt3 {
  margin-top: 3rem;
}

.mr1 {
  margin-right: 1rem;
}

.mbHalf {
  margin-bottom: .5rem;
}

.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}

.mb3 {
  margin-bottom: 3rem;
}

.mt-1 {
  margin-top: -1rem;
}

.mt-2 {
  margin-top: -2rem;
}

.ml-2 {
  margin-left: 2rem;
}


.ml-3 {
  margin-left: 3rem;
}



.pt1 {
  padding-top: 1rem;
}

.pt2 {
  padding-top: 2rem;
}

.pt3 {
  padding-top: 3rem;
}

.pb1 {
  padding-bottom: 1rem;
}

.pb2 {
  padding-bottom: 2rem;
}

.pb3 {
  padding-bottom: 3rem;
}

.ph1 {
  padding: 1rem 0;
}

.ph2 {
  padding: 2rem 0;
}

.ph3 {
  padding: 3rem 0;
}

.pt0 {
  padding-top: 0;
}

.p0 {
  padding: 0;
}

.p1 {
  padding: 1rem;
}

.p2 {
  padding: 2rem;
}

.p3 {
  padding: 3rem;
}

.pr1 {
  padding-right: 1rem;
}

.pl1 {
  padding-left: 1rem;
}

@media only screen and (max-width: 575px) {

  .mt1Mobile {
    margin-top: 1rem;
  }

  .mt2Mobile {
    margin-top: 2rem;
  }

  .mb2Mobile {
    margin-bottom: 2rem;
  }

  .mb3Mobile {
    margin-bottom: 3rem;
  }

  .pt1-5Mobile {
    padding-top: 1.5rem;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {

  .mt1Landscape {
    margin-top: 1rem;
  }

  .mt2Landscape {
    margin-top: 2rem;
  }

  .mb1Landscape {
    margin-bottom: 1rem;
  }

  .mb2Landscape {
    margin-bottom: 2rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .mt1Tablet {
    margin-top: 1rem;
  }

  .mt2Tablet {
    margin-top: 2rem;
  }

  .mb1Tablet {
    margin-bottom: 1rem;
  }

  .mb2Tablet {
    margin-bottom: 2rem;
  }
}

/* Text Styles */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #FFF;
}

.darkestGray {
  color: var(--dobcon-darkest-gray);
}

.darkGray {
  color: var(--dobcon-dark-gray);
}

.gray {
  color: var(--dobcon-gray);
}

.mediumGray {
  color: var(--dobcon-medium-gray);
}

.lightGray {
  color: var(--dobcon-light-gray);
}

.lighterGray {
  color: var(--dobcon-lighter-gray);
}

.green {
  color: var(--dobcon-green);
}

.bigTitle {
  font-size: 50px;
}

.mediumTitle {
  font-size: 40px;
}

.smallTitle {
  font-size: 30px;
}

.bigText {
  font-size: 25px;
}

.mediumText {
  font-size: 20px;
}

.regularText {
  font-size: 17px;
}

.smallText {
  font-size: 15px;
}

.bigN {
  color: var(--dobcon-lighter-gray);
  font-size: 23.5px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
}

.bigN-h1 {
  font-size: 46.5px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100 !important;
  line-height: 0;
}

.bigN-h3 {
  font-size: 29.5px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
}

.bigN-h4 {
  font-size: 35px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
}

.tac {
  text-align: center !important;
}

.tal {
  text-align: left;
}

.tar {
  text-align: right;
}

.taf {
  text-align: justify;
}

.fw700 {
  font-weight: 700;
}

.fw600 {
  font-weight: 600;
}

.fw500 {
  font-weight: 500;
}

.fw400 {
  font-weight: 400;
}

.fw300 {
  font-weight: 300;
}

.fw100 {
  font-weight: 100;
}

.lh17 {
  line-height: 1.7;
}

.lh15 {
  line-height: 1.5;
}

@media only screen and (max-width: 575px) {

  .bigTitle {
    font-size: 40px;
  }

  .mediumTitle {
    font-size: 35px;
  }

  .smallTitle {
    font-size: 28px;
  }

  .bigText {
    font-size: 20px;
  }

  .tacMobile {
    text-align: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {

  .bigTitle {
    font-size: 45px;
  }

  .mediumTitle {
    font-size: 40px;
  }

  .smallTitle {
    font-size: 35px;
  }

  .bigText {
    font-size: 22.5px;
  }

  .tacMobile {
    text-align: center;
  }

  .mAutoLandscape {
    margin: 0 auto;
  }

  .mt2Landscape {
    margin-top: 2rem;
  }

  .mb2Landscape {
    margin-bottom: 2rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .mAutoTablet {
    margin: 0 auto !important;
  }
}

/* Hr */
.grayLine {
  border-bottom: 1.5px solid var(--dobcon-medium-gray);
}

.grayLineRight {
  border-right: 1.5px solid var(--dobcon-medium-gray);
}

@media only screen and (max-width: 575px) {

  .grayLineRight {
    border-right: none;
    border-bottom: 1.5px solid var(--dobcon-medium-gray);
  }
}

@media only screen and (max-width: 1024px) {

  .noGrayLineRightMobile {
    border-right: none;
    border-bottom: none;
  }
}

/* Display */
.hide {
  display: none;
}

.hideDesktop {
  display: none;
}

@media only screen and (max-width: 575px) {

  .showMobile {
    display: unset;
  }
}

@media only screen and (max-width: 1200px) {

  .hideDesktop {
    display: unset;
  }
}

/* Floating */
.floatRight {
  float: right;
}

.floatLeft {
  float: left;
}

.clearFloat {
  clear: both;
}

/* Tables styles */
.columnMini {
  width: 25px;
}

.columnSmall {
  width: 60px;
}

/* Special */
.cursorPointer {
  cursor: pointer;
}

.basicBorder {
  border-radius: 10px;
}

.opacity5 {
  opacity: .5;
}

.greenDot {
  display: inline-block;
  width: 12.5px;
  height: 12.5px;
  background-color: var(--dobcon-green);
  border-radius: 12.5px;
}

.redDot {
  display: inline-block;
  width: 12.5px;
  height: 12.5px;
  background-color: var(--dobcon-light-red);
  border-radius: 12.5px;
}