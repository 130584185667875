.financialVariablesTable {
  background-color: var(--dobcon-gray);
  border-top: 3px solid var(--dobcon-red);
}

.financialVariablesTableBody ul {
  padding: 1.85rem 2rem 2rem;
}

.financialVariablesTableBody ul p {
  padding: 0 .5rem;
}

.financialVariablesTableBody ul li {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1.5px solid var(--dobcon-medium-gray);
  padding: .25rem .5rem;
  cursor: default;
}

.financialVariablesTableBody ul li p {
  font-weight: 500;
}

.financialVariablesTableBody ul li p::before {
  content: "•";
  color: red;
  margin-left: -1rem;
  margin-right: .25rem;
}

.variableTooltip {
  margin-left: .5rem;
  cursor: pointer;
  color: var(--dobcon-light-gray);
  transition: .5s;
  position: relative;
}

.variableTooltip:hover {
  color: var(--dobcon-medium-gray);
}

.variableTooltipInfo {
  position: absolute;
  background-color: #16171ec2;
  padding: .5rem;
  font-size: 15px;
  border-radius: 5px;
  width: 185px;
  display: none;
  left: 100%;
  bottom: 100%;
  z-index: 10;
}

.variableTooltip:hover .variableTooltipInfo {
  display: block;
  color: #FFF;
}

@media only screen and (max-width: 575px) {

  .financialVariablesTableBody ul {
    width: 100%;
  }

  .financialVariablesTableBody ul li span {
    width: 100%;
  }

  .financialVariablesTableBody ul li span:last-child {
    text-align: left;
  }
}