.containerToRightCharts {
  max-width: 91%;
  margin: 0 auto;
  margin-left: 6.5%;
}


.chartSpace {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* This will place the space evenly between the charts */
  gap: 16px;
  /* This is the space between the charts */
}

.chart-card {
  flex: 1;
  /* Each child will grow to fill the space */
  flex-basis: calc(50% - 32px);
  /* Start with one-third of the space minus gap */
  min-width: 400px;
  /* Cards shouldn't shrink below 400px */
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 16px;
  margin-bottom: 16px;
  color: #fff;
}

.chart-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Chart specific styles */
.chart-container {
  padding: 20px;
  border: 1.5px solid var(--dobcon-medium-gray);
  position: relative;
  width: 100%;
  /* Adjust the height here if you want a fixed height for the chart container */
  min-height: 200px;
  /* Minimum height to make the chart container more rectangular */
  height: auto;
  /* Allows the container to grow in height based on content */
}

.chart-title {
  text-align: center;
  margin-bottom: 12px;
  font-size: 1.5em;
}

/* Styles for the text within the chart card */
.chart-text {
  font-size: 1em;
  line-height: 1.5;
  text-align: center;
}

.download-button {
  padding: 10px 15px;
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #45a049;
}

.upload-input {
  display: none; /* Hide the default file input */
}

.upload-label {
  padding: 10px 15px;
  background-color: #008CBA; /* Blue */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.upload-label:hover {
  background-color: #007B9A;
}
