.searchBar {
  position: relative;
  width: 100%;
}

.searchBar .searchBarIcon {
  position: absolute;
  color: var(--dobcon-light-gray);
  top: 9px;
  right: 10px;
}

.searchBar input {
  background-color: var(--dobcon-darkest-gray);
  border: 1.5px solid var(--dobcon-medium-gray);
  border-radius: 1.25rem;
  padding: .5rem 1.4rem .5rem .6rem;
  width: 100%;
}

/* Sizes */ 
.searchBarMini {
  width: 16.3%;
}

.searchBarSmall {
  width: 23.5%;
}

.searchBarMedium {
  width: 32%;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .searchBarMedium {
    width: 40%;
  }
}