.stepTag {
  padding: .35rem 2rem;
  background-color: var(--dobcon-medium-red);
  margin-top: -1px;
  display: inline-block;
}

.toolchainContainer {
  display: -webkit-box;
  overflow-x: auto;
  height: 630px;
  padding-bottom: .5rem;
}