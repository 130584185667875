.mainHeader {
  background-color: var(--dobcon-darkest-gray);
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0 .8rem;
}

.headerLogo {
  height: 23.5px;
}