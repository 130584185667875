.adoptionCard {
  border: 1px solid var(--dobcon-medium-gray);
  border-radius: 10px;
  width: 23.25%;
  margin-right: .75rem;
  position: relative;
  text-align: center;
}

.adoptionCardHead {
  background-color: var(--dobcon-darkest-gray);
  padding: 1rem 1.5rem;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
}

.adoptionCardBody {
  height: 277.5px;
  padding: 1.5rem;
}

.adoptionCardFooter button {
  width: 100%;
  text-align: center;
  padding: .75rem;
  background-color: var(--dobcon-red);
  border-radius: 0px 0px 10px 10px;
}

.adoptionCardFooter {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.adoptionCardFooter button:hover {
  background-color: var(--dobcon-dark-red);
}

/* Modal */
.videoModalBack {
  background-color: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: .5s;
  opacity: 0;
}

.videoModalContent {
  width: 70%;
  position: relative;
}

.videoModalContent video {
  width: 100%;
}

.videoModalContent .closeVideo {
  position: absolute;
  top: 5px;
  right: 7.5px;
  cursor: pointer;
  color: var(--dobcon-medium-gray);
  z-index: 100;
  font-size: 25px;
  transition: .5s;
}

.videoModalContent .closeVideo:hover {
  color: var(--dobcon-light-gray);
}

.videoModalBack.modalActive {
  animation: modalAppears .5s forwards;
}

@keyframes modalAppears {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 575px) {

  .adoptionCard {
    width: 90%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {

  .adoptionCard {
    width: 45%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .adoptionCard {
    width: 40%;
  }
}