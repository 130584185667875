.passCheckContainer {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-bottom: 5px;
}

.passCheckContainer .passCheck {
  width: 15px;
  height: 15px;
  border: 1px solid var(--dobcon-medium-gray);
  border-radius: 10px;
  margin-right: 10px;
}

.passCheckContainer .passCheck.passCheckActive {
  border: 2px solid var(--dobcon-light-gray);
  background-color: var(--dobcon-medium-gray);
}