.basicModalBack {
  background-color: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: .5s;
  opacity: 0;
}

.basicModalContent {
  background-color: var(--dobcon-gray);
  padding: 2.5rem;
  width: 375px;
  border-radius: 10px;
}

.basicModalBack.modalActive {
  animation: modalAppears .5s forwards;
}

@keyframes modalAppears {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
