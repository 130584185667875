.toolchainSlaCard {
  width: 13.7%;
  background-color: var(--dobcon-gray);
  border: 1.5px solid var(--dobcon-medium-gray);
  border-top: 5px solid var(--dobcon-red);
  border-radius: 5px;
  text-align: center;
  padding: .55rem;
}

@media only screen and (max-width: 575px) {
  
  .toolchainSlaCard {
    width: 100%;
    margin-bottom: 1rem;
    padding: 1rem 2rem;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {

  .toolchainSlaCard {
    width: 32%;
    margin-bottom: 1rem;
    padding: .75rem 1rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .toolchainSlaCard {
    width: 24%;
    margin-bottom: 1rem;
    padding: .75rem 1rem;
  }
}