.dobconDashboardContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.dashboardTitle {
  font-size: 28px;
  color: #FFFFFF;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
}

.tabsContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tabButton {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #1E2B38;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-size: 16px;
}

.tabButton.active {
  background-color: #770513;
}

.tabButton:hover {
  background-color: #550513; /* Hover effect */
}

.inputsContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Responsive wrap */
  gap: 10px; /* Gap between input fields */
}

.selectContainer {
  flex: 1;
  margin-right: 10px;
}

.selectContainer:last-child {
  margin-right: 0; /* Remove margin for the last select */
}

.selectContainer label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #FFFFFF;
}

.chartContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: calc(100vh - 200px); /* Adjust to fit within viewport */
  overflow: hidden;
}

.productivityChartsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two charts per row */
  gap: 20px;
  width: 100%;
  max-height: calc(100vh - 200px); /* Adjust to fit within viewport */
  overflow: hidden;
}

.productivityChartItem {
  width: 100%;
  height: 250px; /* Adjust based on your layout needs */
}

.chartDescription {
  margin-top: 20px;
  color: #FFFFFF;
  background-color: #364759;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.chartDescription h3 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #D4D4D4;
}

.chartDescription p, .chartDescription ul {
  margin: 5px 0;
  color: #B0B0B0;
  font-size: 16px;
  line-height: 1.5;
}

.chartDescription strong {
  color: #FFFFFF;
  font-weight: bold;
}

.chartDescription p.advice {
  background-color: #4F5A65;
  padding: 10px;
  border-radius: 4px;
  color: #E0E0E0;
  margin-top: 10px;
}
