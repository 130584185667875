.selectInput {
  position: relative;
  margin-bottom: 1.65rem;
  cursor: pointer;
}

.selectInputContainer {
  text-align: left;
  background-color: transparent;
  border: 1.5px solid var(--dobcon-medium-gray);
  border-radius: 5px;
  padding: .85rem .75rem;
  width: 100%;
  position: relative;
}

.selectInput label {
  position: absolute;
  left: 12px;
  top: -10px;
  background-color: var(--dobcon-gray);
  padding: 0 5px;
  color: var(--dobcon-lighter-gray);
  z-index: 1;
  font-size: 15px;
}

.selectInputSelected {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectInputOptions {
  width: 100%;
  max-height: 150px;
  border: 1px solid var(--dobcon-medium-gray);
  background-color: var(--dobcon-gray);
  overflow-y: auto;
  position: absolute;
  z-index: 100;
  left: 0;
  top: 50px;
  box-shadow: 0px 6px 41px -15px rgba(0,0,0,0.75);
}

.selectInputOptions.open-upwards {
  bottom: 38px; /* Adjust based on your design */
  top: auto; /* Override the default top value */
}

.selectInputOptions:last-child {
  border-bottom: none;
}

.selectInputOption {
  padding: .35rem .5rem;
  cursor: pointer;
  border-bottom: 1px solid var(--dobcon-medium-gray);
  transition: .5s;
}

.selectInputOption:hover {
  background-color: var(--dobcon-dark-gray);
}