.toolchainCard {
  width: 13.7%;
  border: 1.5px solid var(--dobcon-medium-gray);
  border-radius: 5px;
  text-align: center;
  margin-right: 8px;
}

.toolchainCard:last-child {
  margin-right: 0;
}

.toolchainCardHead {
  background-color: var(--dobcon-gray);
  border-radius: 5px 5px 0 0;
  padding: .75rem 0;
  border-top: 5px solid var(--dobcon-red);
  margin-top: -1px;
}

.toolchainCard li {
  height: 55px;
  transition: .5s;
  cursor: pointer;
}

.toolchainCard li:hover {
  background-color: var(--dobcon-darkest-gray);
}

.toolchainCard li img {
  width: 90%;
}

@media only screen and (max-width: 1024px) {

  .toolchainCard {
    width: 160px;
  }
}