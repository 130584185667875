.sidebar {
  width: 60px;
  height: 100%;
  background-color: #28283cf6;
  position: fixed;
  top: 0;
  z-index: 100;
  overflow: hidden;
  transition: .2s;
}

.sidebar:hover {
  width: 230px;
}

.sidebarMenu {
  margin-top: 60px;
  padding: 0 .5rem;
}

.sidebarMenu li {
  border-top: 2px solid var(--dobcon-medium-gray);
  transition: .3s;
}

.sidebarMenu li:hover {
  background-color: var(--dobcon-dark-gray);
}

.sidebarMenu li a,
.sidebarbtn {
  background-color: transparent;
  display: flex;
  height: 55px;
  width: 217.5px;
  align-items: center;
}

.sidebarMenu li h4 {
  margin: .2rem 1rem 0rem;
  color: var(--dobcon-lighter-gray);
}

.sidebarIcons {
  width: 30px;
  margin-left: .5rem;
  opacity: .5;
}

.sidebarMenu li a.active .sidebarIcons {
  opacity: 1;
}

@media only screen and (max-width: 575px) {

  .sidebar {
    width: 100%;
    height: 60px;
    top: unset;
    bottom: 0;
  }

  .sidebar:hover {
    width: 100%;
  }

  .sidebarMenu {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
  }

  .sidebarMenu li {
    border-top: 0;
  }

  .sidebarMenu li a,
  .sidebarbtn {
    width: 14%;
  }

  .sidebarMenu li h4 {
    margin: 0;
    display: none;
  }
}

@media only screen and (min-width: 576px) and (max-width: 914px) {

  .sidebar:hover {
    width: 60px;
  }

  .sidebarMenu {
    margin-top: -2px;
  }

  .sidebarMenu li:hover {
    background-color: var(--dobcon-gray);
  }

  .sidebarIcons {
    width: 27px;
  }

  .sidebarMenu li h4 {
    display: none;
  }

  .sidebarMenu li a {
    width: 60px;
    height: 50px;
  }
}