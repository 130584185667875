.chartModal canvas {
  position: relative;
}

.chartjs-render-monitor {
  position: relative;
}

.chart-container {
  position: relative;
  width: '100%';  
  height: '800px';
  overflow-y: auto;
  overflow-x: auto;
}

.y-axis-labels {
  position: sticky;
  left: 0;
  z-index: 10;
  background: white;
}

