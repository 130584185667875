.adminLoginForm {
  background-color: var(--dobcon-gray);
  padding: 2.5rem;
  width: 465px;
}

.imageForm {
  width: 60%;
}

@media only screen and (max-width: 575px) {

  .adminLoginForm {
    width: 100%;
    padding: 2.5rem 2rem;
  }
}