.formModalBack {
  background-color: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: .5s;
  opacity: 0;
}

.formModalContent {
  background-color: var(--dobcon-gray);
  padding: 2.5rem;
  width: 425px;
  max-height: 530px;
  border-radius: 10px;
  text-align: center;
  overflow: auto;
}

.formModalContent::-webkit-scrollbar {
  border-radius: 0 10px 10px 0;
}

.formModalContent::-webkit-scrollbar-track {
  background-color: var(--dobcon-gray);
  border-radius: 0 10px 10px 0;
}

.formModalBack.modalActive {
  animation: modalAppears .5s forwards;
}

@keyframes modalAppears {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 575px) {

  .formModalContent {
    padding: 2rem;
    width: 95%;
    max-height: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {

  .formModalContent {
    padding: 2rem;
    width: 50%;
    max-height: 95%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .formModalContent {
    padding: 2rem;
    max-height: 90%;
  }
}