.fullWidth {
  width: 100%;
  margin-top: 2em;
}

.settings{
  border: 1px solid var(--dobcon-gray);
  padding: 1em;
  margin: 1em 0;
}

.red {
  background-color: var(--dobcon-red) !important; 
}

.filterContainer {
  max-height: 125px;
  overflow: auto;
  margin-bottom: 20px;
}

.initiativesInfoHead {
  background-color: var(--dobcon-gray);
  display: block;
  padding: .5rem .75rem;
}

.initiativeFirst {
  border-radius: 5px 0 0 5px;
}

.initiativeLast {
  border-radius: 0 5px 5px 0;
}

.initiativesInfoBody {
  padding: .5rem .75rem;
  max-height: 92.5px;
  overflow: auto;
  font-weight: 500;
}

.form-group {
  margin-bottom: 20px; /* Added margin for better spacing */
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.form-control {
  width: 100%;
  padding: .5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: black; /* Text inside input fields will be black */
}

.formTable {
  width: 100%;
  border-collapse: collapse;
}

.formTable th, .formTable td {
  border: 1px solid Var(--dobcon-gray);
  padding: 10px;
  text-align: left;
}

.formTable th {
  background-color: var(--dobcon-gray);
}

.submitButton {
  display: inline-block;
  padding: 12px 24px;
  font-size: 16px;
  color: #fff;
  background-color: var(--dobcon-red);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: gray; /* Change to gray on hover */
}

.submitButton svg {
  margin-right: 8px;
}
