.switchToggle {
  width: 40px;
  height: 20px;
  background-color: var(--dobcon-dark-gray);
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  margin-right: .5rem;
}

.switchHandle {
  width: 18px;
  height: 18px;
  background-color: var(--dobcon-medium-red);
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
}

.switchToggle.active .switchHandle {
  background-color: var(--dobcon-green);
  transform: translateX(20px);
}
