.dataDetails {
  margin: .5rem;
  cursor: pointer;
  transition: .5s;
  position: relative;
}

.dataDetails i {
  color: var(--dobcon-light-gray);
}

.dataDetails.disabled i {
  color: var(--dobcon-medium-gray);
}