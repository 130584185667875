.progressBar {
  width: 69%;
  background-color: var(--dobcon-dark-gray);
  height: 6px;
  border-radius: 5px;
  overflow: hidden;
  margin: .35rem .35rem 0 0;
}

.progress {
  height: 100%;
  background-color: #F2F2F2;
  transition: width 0.3s ease-in-out;
}

.progressFail {
  height: 100%;
  background-color: #F2F2F2;
  transition: width 0.3s ease-in-out;
}