.businessMetricsTable {
  background-color: var(--dobcon-darkest-gray);
  border-top: 3px solid var(--dobcon-red);
}

.businessMetricsTableBody ul {
  padding: 1.85rem 2rem 2rem;
}

.businessMetricsTableBody ul p {
  padding: 0 .5rem;
}

.businessMetricsTableBody ul li {
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1.5px solid var(--dobcon-medium-gray);
  padding: .15rem .5rem;
  cursor: default;
  transition: .5s;
}

.businessMetricsTableBody ul li:hover {
  background-color: #00000049;
}

.businessMetricsTableBody ul li span:last-child {
  text-align: right;
}

@media only screen and (max-width: 575px) {

  .businessMetricsTableBody ul {
    width: 100%;
  }

  .businessMetricsTableBody ul li span {
    width: 100%;
  }

  .businessMetricsTableBody ul li span:last-child {
    text-align: left;
  }
}