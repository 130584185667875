.businessFilterPill {
  position: relative;
  margin: 0 1rem 1rem 0;
  background-color: var(--dobcon-medium-red);
  display: flex;
  height: auto;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding-left: .75rem;
  cursor: default;
  font-weight: 500;
}

.businessFilterPillTitle {
  padding: .4rem;
}

.businessFilterPillOptionsContainer {
  margin-left: .75rem;
  background-color: var(--dobcon-red);
  display: flex;
  align-items: center;
  border-radius: 0 5px 5px 0;
}

.businessFilterPillOptionsContainer button {
  background-color: var(--dobcon-red);
  height: 100%;
  padding: .5rem;
  border-radius: 0px;
  opacity: .75;
  font-size: 15px;
  line-height: 1;
}

.businessFilterPillOptionsContainer button.pillDisabled {
  cursor: default;
}

.businessFilterPillOptionsContainer button:hover.pillDisabled {
  background-color: var(--dobcon-red);
}

.selectFilter {
  width: 17px;
  height: 17px;
  border-radius: 17px;
  border: 2.5px solid #FFF;
}

.selectFilter.selected {
  background-color: #ffffff85;
}

.businessFilterPillOptionsContainer button:last-child {
  border-radius: 0 5px 5px 0;
  border-left: 1px solid var(--dobcon-dark-red);
}

.businessFilterPillOptionsContainer button:hover {
  background-color: var(--dobcon-dark-red);
}