.initiativeResourcesTable {
  width: 100%;
  position: relative;
  max-height: 450px;
  overflow: auto;
}

.initiativeResourcesTable table {
  width: 100%;
}

.initiativeResourcesTableHead {
  background-color: var(--dobcon-darkest-gray);
  text-align: left;
  position: sticky;
  top: 0;
}

.initiativeResourcesTableHead tr th {
  border-top: 3px solid var(--dobcon-red);
  padding: 1.25rem 1rem;
  border-bottom: 1.5px solid var(--dobcon-medium-gray);
  border-right: 1.5px solid var(--dobcon-medium-gray);
}

.initiativeResourcesTableHead tr th:last-child {
  border-right: none;
}

.initiativeResourcesTableBody {
  background-color: #16171e79;
}

.initiativeResourcesTableBody tr:hover {
  background-color: var(--dobcon-darkest-gray);
}

.initiativeResourcesTableBody tr td {
  border-bottom: 1.5px solid var(--dobcon-medium-gray);
  border-right: 1.5px solid var(--dobcon-medium-gray);
}

.initiativeResourcesTableBody tr td:last-child {
  border-right: none;
}

.initiativeResourcesTableBody tr td ul {
  max-height: 140px;
  overflow: auto;
}

.initiativeResourcesTableBody tr td ul li {
  font-weight: 500;
  border-top: 1.5px solid var(--dobcon-medium-gray);
  padding: .25rem 0;
  padding: 1rem;
}

.initiativeResourcesTableBody tr td ul li:first-child {
  border-top: none;
}