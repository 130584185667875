.initiativePill {
  display: inline-block;
  background-color: var(--dobcon-gray);
  border: 1.5px solid var(--dobcon-medium-gray);
  border-radius: 5px;
  padding: .35rem .75rem;
  margin: .1rem .85rem 1.15rem 0rem;
}

.initiativePill:hover {
  background-color: var(--dobcon-dark-gray);
}