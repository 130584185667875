.licensesTable {
  width: 100%;
  max-height: 450px;
  border: 1px solid var(--dobcon-medium-gray);
  border-radius: 5px;
  background-color: var(--dobcon-gray);
}

.licensesTableHead {
  padding: 1.45rem 1rem;
  background-color: var(--dobcon-dark-gray);
  border-radius: 5px 5px 0 0;
  border-bottom: 3px solid var(--dobcon-red);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.licensesTableBody {
  width: 100%;
  max-height: 270px;
  overflow-y: auto;
}

.licensesTable table {
  width: 100%;
  table-layout: auto;
}

.licensesTable table tr th {
  padding: 1rem .5rem;
  border-bottom: 1px solid var(--dobcon-medium-gray);
  background-color: var(--dobcon-gray);
  text-align: left;
  position: sticky;
  top: 0;
  font-size: 18.5px;
}

.licensesTable table tr th:first-child,
.licensesTable table tr td:first-child {
  padding-left: 1rem;
}

.licensesTable table tr th:last-child,
.licensesTable table tr td:last-child {
  padding-right: 1rem;
}

.licensesTable table tr td {
  font-weight: 500;
  padding: .5rem;
  border-bottom: 1px solid var(--dobcon-medium-gray);
}

.licensesTable table tr:hover {
  background-color: var(--dobcon-dark-gray);
}

.licensesTable table tr td button {
  color: var(--dobcon-light-gray);
}

.licensesTable table tr td button:hover {
  color: var(--dobcon-medium-gray);
}

.licensesTableFooter {
  padding: 1rem;
  position: relative;
  z-index: 1;
}
