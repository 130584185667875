

svg {
  width: 20vw;
  height: 18vh;
  display: block;
}

.outside,
.inside {
  fill: none;
}

.outside {
  stroke-width: inherit;
  stroke: var(--dobcon-dark-red);
  stroke-opacity: 0.3;
}

.inside {
  stroke: var(--dobcon-light-red);
  animation: loop 1.5s linear infinite;
  stroke-width: 1.0;
  stroke-dasharray: 3 24.5;
  animation-delay: -1.5s;
}

.infinity {
  stroke-width: 1.0;
  animation: rot 36s linear infinite;
}

.shadow {
  animation: raise 3s linear infinite;
}

.shadow.odd {
  animation-delay: -1s;
}

@keyframes raise {
  0%, 20% {
    opacity: 1;
  }

  30%, 70% {
    opacity: 0;
  }

  80%, 100% {
    opacity: 1;
  }
}

@keyframes loop {
  0% {
    stroke-dashoffset: 0.5;
  }

  100% {
    stroke-dashoffset: 28;
  }
}

.clip-shadow {
  stroke-linecap: butt;
  fill: none;
  opacity: 0.3;
  stroke-width: inherit;
  stroke: url(#rgradient);
}

#rgradient stop {
  stop-color: #2345;
}