.graphCard {
  border: 1.5px solid var(--dobcon-medium-gray);
  border-radius: 5px;
}

.graphCardHead {
  padding: 0rem 1rem;
  height: 70px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.graphCardBody {
  padding: 0 1rem 1rem;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  min-height: 70px;
}

.graphCardFooter {
  background-color: var(--dobcon-gray);
  padding: .75rem .5rem 0;
  border-radius: 0 0 5px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}