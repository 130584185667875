.dropdownSearchbar {
  position: relative;
  width: 100%;
}

.dropdownSearchbar input {
  background-color: var(--dobcon-dark-gray);
  border: 1.5px solid var(--dobcon-medium-gray);
  border-radius: 5px;
  padding: .5rem;
  width: 100%;
}

.dropdownSearchbarOptions {
  max-width: 200px;
  max-height: 150px;
  border: 1px solid var(--dobcon-medium-gray);
  background-color: var(--dobcon-darkest-gray);
  overflow-y: auto;
  position: absolute;
  z-index: 100;
  box-shadow: 0px 6px 41px -15px rgba(0,0,0,0.75);
}

.dropdownSearchbarOptions:last-child {
  border-bottom: none;
}

.dropdownSearchbarOption {
  padding: .35rem .5rem;
  cursor: pointer;
  border-bottom: 1px solid var(--dobcon-medium-gray);
  transition: .5s;
}

.dropdownSearchbarOption:hover {
  background-color: #00000049;
}