.chartModal canvas {
  position: relative;
}

.chartjs-render-monitor {
  position: relative;
}

.chart-container {
  position: relative;
  display: flex;
}

.y-axis-labels {
  position: sticky;
  left: 0;
  z-index: 10;
  background: white;
}
