.statusPill {
  padding: .5rem .5rem;
  font-weight: 500;
  border-radius: 1rem;
  text-align: center;
}

.fail {
  border: 1px solid var(--dobcon-medium-red);
  background-color: #77051283;
}

.success {
  border: 1px solid var(--dobcon-green);
  background-color: #26780556;
}

.pending {
  border: 1px solid #f8e807;
  background-color: #f8e80782;
}

.pending-mod {
  border: 1px solid #f8e807;
  background-color: #ffef11cc;
  color: black;
  font-weight: 600;
}