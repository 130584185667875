.numberInput {
  position: relative;
  width: 100%;
}

.numberInput .numberInputSymbol {
  position: absolute;
  color: var(--dobcon-light-gray);
}

.numberInput .cost {
  top: 7px;
  left: 7px;
}

.numberInput .percentage {
  top: 7px;
  right: 7px;
}

.numberInput .symbolWithLabel {
  top: 36px;
}

.numberInput input {
  background-color: var(--dobcon-dark-gray);
  border: 1.5px solid var(--dobcon-medium-gray);
  border-radius: 5px;
  padding: .35rem 1.35rem .35rem .35rem;
  width: 100%;
  text-align: right;
}

.hasLabel input {
  margin-top: .5rem;
}

.numberInputCost input {
  padding: .35rem .35rem .35rem .35rem;
}

.numberInput label {
  margin: 0rem .5rem;
  color: var(--dobcon-lighter-gray);
}

/* Backgrounds */
.numberInputDarkest input {
  background-color: var(--dobcon-darkest-gray);
}

/* Sizes */
.numberInputMini {
  width: 25%;
}

.numberInputMedium {
  width: 45%;
}

/* Colors */
.darkestInput input {
  background-color: var(--dobcon-darkest-gray);
  border: 1.5px solid var(--dobcon-medium-gray);
}